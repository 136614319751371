// import Home from './components/Home'
import SSO from './components/SSO'
import Login from './components/Login'

export const routes = [
  // { path: '', component: Login, },
  {
    path: '/',
    component: Login,
    beforeEnter() {
      window.location.href = "https://library.highlights.com/member/login";
    }
  },
  { path: '/sso', component: SSO, },
  { path: '/ues', component: Login, },
  { path: '*', redirect: '/', },
];
