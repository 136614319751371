<template>
    <div class="full">
      <div class="loading" v-if="loading">Loading</div>
      <div class="login_area">
        <div class="login_form">
          <img src="/assets/img/hll-logo.png" class="hll_logo" alt="HLL Logo">
          <input class="form-input" type="text" v-model="username" placeholder="username" />
          <input class="form-input" type="password" v-model="password" placeholder="password" />
          <button class="form-submit-button" @click.prevent="doLogin" type="button">Login</button>
          <br />
          <div v-if="error" class="error">
            <strong>HATA:</strong> {{ errorMessage }}
          </div>
        </div>

<!--        <div class="info_area" style="font-family: 'Arial', serif; font-size: 14px; line-height: 20px; text-align: center;">-->
<!--          Sevgili öğretmenlerimiz;<br />-->
<!--          Highlights Library sitesinde güncelleme yapılmaktadır. Bu süre zarfında hesabınıza tanımlı sınıflarınızdan sadece biri görünüyor olacaktır. Kısa zaman içinde bu güncelleme tamamlanacaktır.<br />-->
<!--          Anlayışınız için teşekkür ederiz.-->
<!--          <hr style="border: 1px solid #ffffff;" />-->
<!--          Öğrencilerimiz için bir sorun yoktur.-->
<!--        </div>-->
      <div class="help">HELP DESK: 0850 550 50 90 </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      api_key: "4i61GWTokgwHc35TzSDaj1KmXif91cx7tK93GgBNaFGzTElB",
      username: null,
      password: null,
      userDataHLL: {},
      error: false,
      errorMessage: null,
    }
  },

  methods: {
    doLogin() {
      this.ueslmsLogin()
    },

    ueslmsLogin() {
      this.loading = true
      axios.post('https://www.ueslms.com/api/v2/authenticate', { email: this.username, password: this.password, api_key: this.api_key } )
        .then(response => {
          if (response.data.status_code === 200) {
            this.ueslmsCheckUser()
          }
        })
        .catch(e => {
          if (e.response.status !== 200) {
            const message = e.response.data.error
            console.log('ERR', message)
            this.error = true
            this.loading = false
            this.errorMessage = "Kullanıcı bulunamadı."
          }
        })
    },

    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check', { user: this.username } )
          .then(response => {
            if (response.data.success) {
              this.userDataHLL = response.data.user
              if (!this.userDataHLL.class_name) {
                this.error = true
                this.loading = false
                this.errorMessage = "Lütfen destek@eltturkey.com adresi ile iletişime geçiniz. Okul, kampüs ve sınıf bilgilerinizi iletmeyi lütfen unutmayınız."
              } else {
                this.goHLL()
              }
            }
          })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },

    goHLL() {
      if (this.userDataHLL === undefined || !this.userDataHLL.company_code) {
        this.error = true
        this.loading = false
        this.errorMessage = 'Company Code LMS sisteminde tanımlı değil.'
      }

      // console.log(this.userDataHLL)

      if (!this.error) {
        window.go_hll(this.userDataHLL)
      }
    },

  }
}
</script>

<style scoped>
 .full {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100vh;
    background: url("/assets/img/bg_login.png");
    background-size: cover;
  }

 .info_area {
   background-color: #7e0cdc;
   color: #ffffff;
   padding: 10px;
 }

  .login_area {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 350px;
    height: 400px;
    background-color: #ffffff;
    border-top: 5px solid #248ab2;
  }

  .login_form {
    padding: 20px 10px;
    text-align: center;
  }

  .hll_logo {
    margin: 0 auto 30px auto;
    width: 150px;
    display: block;
  }

  .form-input {
    font-family: 'Arial', serif;
    border: 2px solid #248ab2;
    border-radius: 10px;
    margin: 3px 0 6px 0;
    display: block;
    padding: 10px;
    width: 300px;
    font-size: 16px;
    outline: none;
  }

  .form-submit-button {
    font-family: 'Arial', serif;
    border: 2px solid #248ab2;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    display: block;
    padding: 10px;
    width: 100%;
    margin: 20px 0 0 0;
    background-color: #248ab2;
    color: #ffffff;
    cursor: pointer;
  }

  .error {
    font-family: 'Arial', serif;
    color: #ffffff;
    background-color: #cc0000;
    padding: 4px;
    border-radius: 6px;
  }

  .help {
    font-family: 'Arial', serif;
    font-size: 11px;
    text-align: center;
    padding-top: 65px;
    color: #a9a9a9;
    border-radius: 10px;
    width: 300px;
    margin: 0 auto;
  }

 /* Absolute Center Spinner */
 .loading {
   position: fixed;
   z-index: 999;
   height: 2em;
   width: 2em;
   overflow: show;
   margin: auto;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
 }

 /* Transparent Overlay */
 .loading:before {
   content: '';
   display: block;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

   background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
 }

 /* :not(:required) hides these rules from IE9 and below */
 .loading:not(:required) {
   /* hide "loading..." text */
   font: 0/0 a;
   color: transparent;
   text-shadow: none;
   background-color: transparent;
   border: 0;
 }

 .loading:not(:required):after {
   content: '';
   display: block;
   font-size: 10px;
   width: 1em;
   height: 1em;
   margin-top: -0.5em;
   -webkit-animation: spinner 150ms infinite linear;
   -moz-animation: spinner 150ms infinite linear;
   -ms-animation: spinner 150ms infinite linear;
   -o-animation: spinner 150ms infinite linear;
   animation: spinner 150ms infinite linear;
   border-radius: 0.5em;
   -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
   box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
 }

 /* Animation */

 @-webkit-keyframes spinner {
   0% {
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     -moz-transform: rotate(360deg);
     -ms-transform: rotate(360deg);
     -o-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 @-moz-keyframes spinner {
   0% {
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     -moz-transform: rotate(360deg);
     -ms-transform: rotate(360deg);
     -o-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 @-o-keyframes spinner {
   0% {
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     -moz-transform: rotate(360deg);
     -ms-transform: rotate(360deg);
     -o-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 @keyframes spinner {
   0% {
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     -moz-transform: rotate(360deg);
     -ms-transform: rotate(360deg);
     -o-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }


</style>
